export class Config {
    //PROD
    //webAPIUrl = 'https://api.cpmgg.com.sg/'
    //signalRUrl="https://api.cpmgg.com.sg:5008/signalr"

    //UAT 
    webAPIUrl = 'http://uat.api.cpmgg.com.sg/'
    signalRUrl = 'http://uat.api.cpmgg.com.sg:8035/signalr'

    //SIT
     //webAPIUrl = 'http://sit.goldpro.api.stoneinno.com/'
    // signalRUrl = 'http://47.91.231.122:5006/signalr'

    //LOCAL
    // webAPIUrl = 'http://localhost:63670/'
    // signalRUrl = 'http://127.0.0.1:9912/signalr'

    signalRProxyName = 'PricingBroadcasterHub'
    signalRNotifySpotPrice = 'NotifySpotPrice'
    signalRNotifyProductPrice = 'NotifyProductPrice'
}
